import * as React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { deviceBreakpoints } from '../../../../hooks/useDeviceQuery.hook';
import { FreeUserSignUp } from '../../../Auth/FreeUserSignUp';
import { colorsV2 } from '../../../colors-v2';
import { HeaderActionSearch } from '../Header/HeaderActionSearch';
import { TopbarNavList } from '../Header/TopbarNavList/TopbarNavList';
import { LandingLayoutHeader } from '../LandingLayout';
import { pageViewEvent, pageViewEventPixelSDK } from '../api/user-pixel';
import {isBrowser} from "@doltech/utils/lib/platform";

const Main = styled(LandingLayoutHeader).withConfig({
  componentId: 'PremadeHeader_LandingHeaderFullWidth_Main',
} as any)`
  .fake-header {
    height: 64px;
  }
  padding: 0 16px;
  &.landing-header {
    display: grid;
    align-items: stretch;
    justify-content: center;
    grid-template-columns: 1fr;
    z-index: 201;

    .landing-header-container {
      height: 64px;
      justify-content: space-between;
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      grid-template-columns: max-content;
      gap: 24px;
      &.hide {
        padding: 0 16px;

        @media ${deviceBreakpoints.fromTablet} {
          padding: 0 24px;
        }

        @media ${deviceBreakpoints.fromDesktop} {
          padding: 0 32px;
        }

        filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04))
          drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0 1px rgba(0, 0, 0, 0.04));
        z-index: 1000;
        background: ${colorsV2.white100};
        width: 100%;
        grid-auto-flow: column;
        grid-template-columns: max-content;
        display: grid;
        position: fixed;
        top: 0;
        left: 0;
      }
    }

    .section-header {
      height: 100%;
      &-left,
      &-middle,
      &-right {
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        grid-template-columns: max-content;
        gap: 12px;
      }

      &-middle {
        display: none;
        flex: 1;
        justify-content: flex-end;
      }
    }

    .btn-sidebar {
      display: flex;
    }
    @media ${deviceBreakpoints.fromTablet} {
      padding: 0 24px;
    }

    @media ${deviceBreakpoints.fromDesktop} {
      padding: 0 32px;
      &.landing-header {
        .landing-header-container {
          grid-template-columns: max-content 1fr max-content;

          .section-header {
            &-middle {
              display: grid;
            }
          }
        }
      }
      .btn-sidebar {
        display: none;
      }
    }
  }
`;

const FreeUserSignUpWrapper = styled.div`
  ${toScreen(425)} {
    display: none;
  }
`;

interface LandingHeaderFullWidthProps {
  logoColored?: boolean;
  showBanner?: boolean;
  id?: string;
}

const pixelCode = ` !function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


  ttq.load('CNIPJVBC77UC27SBPDVG');
}(window, document, 'ttq');`

export const LandingHeaderFullWidth = (props: LandingHeaderFullWidthProps) => {
  const { logoColored, ...rest } = props;
  // const { appScrollRef } = useAppScrollRef();

  // useBannerScroll({
  //   ref: {
  //     current: isBrowser() ? appScrollRef.current : null,
  //   },
  //   headerRef: {
  //     current: isBrowser() ? document.getElementById('fixed') : null,
  //   },
  //   bannerId: 'banner-id',
  // });
  React.useEffect(() => {
    function loadScriptIfNeeded() {
      const element = document.querySelector('#pixel-script');
      if (!element) {
        const script = document.createElement('script');
        script.id = 'pixel-script';
        script.type = 'text/javascript';
        script.text = pixelCode;
        script.async = true;
        document.head.appendChild(script);
      }
    }

    loadScriptIfNeeded();
    const currentuser = isBrowser() && localStorage.getItem('auth.currentuser.custom_claim');
    const userMetadata = JSON.parse(currentuser)?.userMetadata;
    pageViewEventPixelSDK(uuidv4());
    pageViewEvent(uuidv4(), userMetadata?.email, userMetadata?.phone);
  }, []);

  return (
    <Main {...rest}>
      <div className="fake-header" />
      <div id="fixed" className="landing-header-container hide">
        <div className="section-header section-header-left">
          <LandingLayoutHeader.BtnSidebar />
          <LandingLayoutHeader.LogoContainer logoColored={logoColored} />
        </div>

        <div className="section-header section-header-middle">
          <TopbarNavList />
        </div>

        <div className="section-header section-header-right">
          <HeaderActionSearch />
          <FreeUserSignUpWrapper>
            <FreeUserSignUp />
          </FreeUserSignUpWrapper>
        </div>
      </div>
    </Main>
  );
};
