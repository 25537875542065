import axios from 'axios';
import Cookies from 'js-cookie';

import { apis } from '@doltech/core/lib/api/api.config';
import { components } from '@doltech/dto';
import { apiUrls } from '@doltech/core/lib/api/api.urls';

enum PixelEvent {
  Contact = 'Contact',
  PageView = 'Pageview',
}

enum Platform {
  TikTok = 'TikTok',
  Facebook = 'Facebook',
}

interface UserDataDTO {
  url?: string;
  client_ip_address?: string;
  client_user_agent?: string;
  fbc?: string;
  fbp?: string;
  em?: string;
  phone_number?: string;
}

interface UserPixelDTO {
  event_id: string;
  pixel: string;
  platforms: Platform[];
  event?: PixelEvent;
  user_data?: UserDataDTO;
}

const header = {
  'Content-Type': 'application/json',
};

const isBrowser = () => typeof window !== 'undefined';
export const getUserIPV6 = () => {
  return axios.get('https://api64.ipify.org/?format=json').then(({ data }) => data.ip);
};

export const pageViewEventPixelSDK = async (event_id: string) => {
  if (!isBrowser()) return;
  (window as any)?.ttq?.track(PixelEvent.PageView, {
    event_id: event_id,
  });
};

export const pageViewEvent = async (event_id: string, email: string, phone_number: string) => {
  const ip: string = await getUserIPV6();
  const userData: UserDataDTO = {
    url: isBrowser() && window?.location?.hostname,
    client_ip_address: ip,
    client_user_agent: isBrowser() && window?.navigator?.userAgent,
    fbc: Cookies.get('fbc') || '',
    fbp: Cookies.get('fbp') || '',
    em: email,
    phone_number: phone_number,
  };

  const userPixel: UserPixelDTO = {
    event_id: event_id,
    pixel: 'tuhoc',
    platforms: [Platform.TikTok, Platform.Facebook],
    event: PixelEvent.PageView,
    user_data: userData,
  };

  await apis.publicGoUserService.post<components['schemas']['ResponseDTO']>(
    apiUrls.goUser.PIXEL_PAGE_VIEW,
    userPixel,
    { headers: header }
  );
};
